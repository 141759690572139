<template>
	<div class="home">
		<div class="header">
			<span>水务系统管理后台</span>
			<el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">{{username}}<i class="el-icon-arrow-down el-icon--right"></i></span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="logout">退出登录</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
		<div class="main">
			<el-menu router @select="selectMenu">
				<el-submenu index="1">
					<template slot="title">
						<i class="el-icon-user-solid"></i>
						<span>用户管理</span>
					</template>
					<el-menu-item index="userList">用户列表</el-menu-item>
				</el-submenu>
				<el-submenu index="2">
					<template slot="title">
						<i class="el-icon-message-solid"></i>
						<span>警告管理</span>
					</template>
					<el-menu-item index="warningList">警告列表</el-menu-item>
					<el-menu-item index="warningTrade">报警趋势</el-menu-item>
				</el-submenu>
				<el-submenu index="3">
					<template slot="title">
						<i class="el-icon-s-tools"></i>
						<span>设备管理</span>
					</template>
					<el-menu-item index="deviceList">设备列表</el-menu-item>
				</el-submenu>
				<el-submenu index="4">
					<template slot="title">
						<i class="el-icon-office-building"></i>
						<span>单位管理</span>
					</template>
					<el-menu-item index="companyList">单位列表</el-menu-item>
				</el-submenu>
			</el-menu>
			<div class="content">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        name: 'Home',
        data() {
            return {
                username: ''
            }
        },
        mounted() {
            try {
                const user = JSON.parse(sessionStorage.getItem('user'));
                if (!user) {
                    this.$message.error('请先登录')
                    this.$router.replace('/login')
                    return
                }
                this.username = user.username
            } catch (e) {
                console.log(e);
                this.$message.error('用户未登录')
                this.$router.replace('/login')
            }
        },
        methods: {
            handleCommand(command) {
                if (command === 'logout') {
                    sessionStorage.removeItem('user')
                    this.$router.replace('/login')
                }
            },
            selectMenu(index) {
                if (index === '1-1') {
                    this.$router.push('/home/userList')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
	.home {
		height: 100%;
		display: flex;
		flex-direction: column;

		.header {
			height: 48px;
			line-height: 48px;
			font-size: 22px;
			color: #409eff;
			text-align: left;
			background-color: #ffffff;
			border-bottom: 1px solid gray;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 20px;
		}

		.main {
			display: flex;
			flex: 1;

			.el-menu {
				width: 180px;

				.el-submenu {
					.el-menu-item {
						min-width: 180px;
					}

					.el-submenu__title {
						background-color: red;
						i {
							margin-left: -20px;
						}
					}
				}
			}

			.content {
				flex: 1;
				padding: 16px;
			}
		}
	}
</style>
